<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12" v-if="whitelabelLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-if="!whitelabelLoading">
          <h3>Add New White Label</h3>
          <v-form>
            <v-label>Name</v-label>
            <v-text-field v-model="name" placeholder="Name" outlined />
            <v-label>Logo Url</v-label>
            <v-text-field v-model="logo" placeholder="Logo" outlined />
            <v-label>Fav Icon Url</v-label>
            <v-text-field v-model="favIcon" placeholder="Fav Icon" outlined />
            <v-label>Primary Color</v-label>
            <v-text-field
              v-model="color1"
              placeholder="Primary Color"
              outlined
            />
            <v-label>Secondary Color</v-label>
            <v-text-field
              v-model="color2"
              placeholder="Secondary Color"
              outlined
            />
            <v-label>Website</v-label>
            <v-text-field
              v-model="website"
              placeholder="Website"
              outlined
            />
            <v-label>Hybrid</v-label>
            <v-checkbox
              v-model="hybrid"
              placeholder="Hybrid"
              outlined
              label="Hybrid"
            />
            <v-btn @click="validate" v-if="!loading">Submit</v-btn>
            <v-btn loading v-if="loading"></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

export default {
  name: "AddWhiteLabel",

  components: {
    Loading,
  },

  data() {
    return {
      snackbar: false,
      text: "",
      name: undefined,
      color1: undefined,
      color2: undefined,
      logo: undefined,
      loading: false,
      website: undefined,
      favIcon: undefined,
      whitelabelLoading: true,
      hybrid: false,
    };
  },

  methods: {
    ...mapActions(["postWhiteLabel", "getWhiteLabel"]),
    async validate() {
      this.loading = true;
      const data = {
        name: this.name,
        color1: this.color1,
        color2: this.color2,
        logo: this.logo,
        website:this.website,
        favIcon:this.favIcon,
        hybrid:this.hybrid,
      };
      if(this.$route.params.id != undefined){
        data._id = this.$route.params.id;
      }
      await this.postWhiteLabel(data);
      this.loading = false;
      this.$router.push("/dashboard/whitelabels")
    },
  },
  computed:{
    ...mapGetters(["allWhiteLabel"])
  },
  async created() {
    if (this.$route.params.id) {
      await this.getWhiteLabel(this.$route.params.id);
      this.name = this.allWhiteLabel.name;
      this.color1 = this.allWhiteLabel.color1;
      this.color2 = this.allWhiteLabel.color2;
      this.logo = this.allWhiteLabel.logo;
      this.website = this.allWhiteLabel.website;
      this.favIcon = this.allWhiteLabel.favIcon;
      this.hybrid = this.allWhiteLabel.hybrid;
    }

    this.whitelabelLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dateHighlight {
  background: yellow;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 0;
}
.v-icon {
  font-size: 16px;
  cursor: pointer;
}
.downloadbtn {
  margin-left: 20px;
}
.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
.heightZero {
  height: 0;
}
.actionbtn {
  margin-bottom: 20px;
}
.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);
  a {
    color: rgb(5, 5, 5);
  }
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}
a {
  text-decoration: none;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;
  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}
.relRow {
  position: relative;
  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }
  &:hover {
    .dropBox {
      display: block;
    }
  }
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 500px;
    height: 500px;
  }
}
.flexBox-space {
  display: flex;
  justify-content: space-between;
}
.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
.flexend {
  justify-content: flex-end;
  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;
  h5 {
    font-size: 16px;
    margin-bottom: 5px;
    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }
  margin-bottom: 20px;
  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;
    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;
      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}
.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}
.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;
  &:nth-of-type(1) {
    left: 280px;
  }
  &:nth-of-type(2) {
    left: 420px;
  }
}
.favourite {
  color: red;
  width: 35px;
  height: 35px;
}
.favouriteO {
  width: 35px;
  height: 35px;
}
.addButton {
  // margin-left: 310px;
  min-width: 200px !important;
}
</style>
