import Dashboard from "../views/dashboard/Dashboard";
const backendmenu = [
  {
    path: "/dashboard",
    component: Dashboard,
    children: [
      {
        path: "/dashboard/qualityreporting",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "DashboardHome" */ "../views/dashboard/views/Home"
          ),
      },
      {
        path: "/dashboard/users",
        name: "User",
        component: () =>
          import(
            /* webpackChunkName: "DashboardHome" */ "../views/dashboard/views/User"
          ),
      },
     
      {
        path: "/dashboard/mldata",
        name: "MLData",
        component: () =>
          import(
            /* webpackChunkName: "MLData" */ "../views/dashboard/views/MLData"
          ),
      },
      {
        path: "/",
        name: "RightmovesDetails",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/Properties"
          ),
      },
      {
        path: "/dashboard/offmarketproperties",
        name: "OffMarketProperties",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/OffMarketProperties"
          ),
      },
      {
        path: "/dashboard/publishedproperties",
        name: "PublishedProperties",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/PublishedProperties"
          ),
      },
      {
        path: "/dashboard/calculator",
        name: "Calculator",
        component: () =>
          import(
            /* webpackChunkName: "Calculator" */ "../views/dashboard/views/Calculator"
          ),
      },
      {
        path: "/dashboard/whitelabels",
        name: "WhiteLabel",
        component: () =>
          import(
            /* webpackChunkName: "WhiteLabels" */ "../views/dashboard/views/WhiteLabel"
          ),
      },
      {
        path: "/dashboard/whitelabel/add",
        name: "AddWhiteLabel",
        component: () =>
          import(
            /* webpackChunkName: "AddWhiteLabel" */ "../views/dashboard/views/AddWhiteLabel"
          ),
      },
      {
        path: "/dashboard/whitelabel/add/:id",
        name: "UpdateWhiteLabel",
        component: () =>
          import(
            /* webpackChunkName: "UpdateWhiteLabel" */ "../views/dashboard/views/AddWhiteLabel"
          ),
      },
      {
        path: "/dashboard/offmarketproperty/:id",
        name: "OffMarketProperty",
        component: () =>
          import(
            /* webpackChunkName: "OffMarketProperty" */ "../views/dashboard/views/SinglePropertyOffMarket"
          ),
      },
      {
        path: "/dashboard/singleProperty/:id",
        name: "SingleProperty",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/SingleProperty"
          ),
      },
      {
        path: "/dashboard/testproperties",
        name: "TestDetails",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/TestPropeties"
          ),
      },
      {
        path: "/dashboard/settings",
        name: "Setting",
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetailsSale" */ "../views/dashboard/views/Setting"
          ),
      },
      {
        path: "/dashboard/emaileditor",
        name: "EmailEditor",
        component: () =>
          import(
            /* webpackChunkName: "EmailEditor" */ "../views/dashboard/views/EmailEditor"
          ),
      },
      {
        path: "/dashboard/emaileditor/:id",
        name: "EmailEditor",
        component: () =>
          import(
            /* webpackChunkName: "EmailEditor" */ "../views/dashboard/views/EmailEditor"
          ),
      },
      {
        path: "/dashboard/emailtemplatelist",
        name: "EmailTemplateList",
        component: () =>
          import(
            /* webpackChunkName: "EmailTemplateList" */ "../views/dashboard/views/EmailTemplateList"
          ),
      },
      {
        path: "/dashboard/rentproperties",
        name: "RentProperties",
        component: () =>
          import(
            /* webpackChunkName: "RentProperties" */ "../views/dashboard/views/RentProperties"
          ),
      },
      {
        path: "/dashboard/singleRentProperty/:id",
        name: "SingleRentProperty",
        component: () =>
          import(
            /* webpackChunkName: "SingleRentProperty" */ "../views/dashboard/views/SingleRentProperty"
          ),
      },
    ],
  },
];

export default backendmenu;
