import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import JsonCSV from 'vue-json-csv'
import VueGraph from "vue-graph";
import GraphLine3D from 'vue-graph/src/components/line3d.js'
import NoteWidget from 'vue-graph/src/widgets/note.js'
import LegendWidget from 'vue-graph/src/widgets/legends.js'
import VueMoment from 'vue-moment';



Vue.use(VueMoment)
 
Vue.component(GraphLine3D.name, GraphLine3D);
Vue.component(NoteWidget.name, NoteWidget);
Vue.component(LegendWidget.name, LegendWidget);

Vue.use(VueGraph)

Vue.component('downloadCsv', JsonCSV)
Vue.config.productionTip = false
export const baseUrl = 'http://betaapi.truevals.com/api/v1/';
// export const baseUrl = 'http://localhost:3002/api/v1/';
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
