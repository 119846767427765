<template>
  <section>
    <div class="left-fix">
      <ul class="first-ul">
        <li v-for="(menu, index) in allAdminMenus" :key="index">
          <router-link
            class="menuitems"
            v-if="menu.submenu.length < 1"
            :to="menu.path"
            ><v-icon class="icon-left">{{ menu.icon }}</v-icon
            >{{ menu.title }}</router-link
          >
          <span class="menuitems" v-if="menu.submenu.length >= 1"
            ><v-icon class="icon-left">{{ menu.icon }}</v-icon
            >{{ menu.title }}
            <v-icon class="icon-right"> mdi-chevron-down </v-icon>
          </span>

          <ul v-for="(submenu, i) in menu.submenu" :key="i" class="second-ul">
            <li>
              <router-link class="menuitems" :to="submenu.path"
                >{{ submenu.title }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link class="menuitems" to="/dashboard/offmarketproperties"
            ><v-icon class="icon-left"> mdi-view-list </v-icon>Off Market
            Properties</router-link
          >
        </li>
          <li>
          <router-link class="menuitems" to="/dashboard/publishedproperties"
            ><v-icon class="icon-left"> mdi-view-list </v-icon>Published
            Properties
          </router-link>
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/rentproperties"
            ><v-icon class="icon-left"> mdi-view-list </v-icon>Rental
            Properties</router-link
          >
        </li>

        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/qualityreporting"
            ><v-icon class="icon-left"> mdi-view-list </v-icon>Quality
            Reporting</router-link
          >
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/mldata"
            ><v-icon class="icon-left"> mdi-view-list </v-icon>ML
            Properties</router-link
          >
        </li>

        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/users"
            ><v-icon class="icon-left"> mdi-account-group-outline </v-icon
            >Users</router-link
          >
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/emailtemplatelist"
            ><v-icon class="icon-left"> mdi-email-edit </v-icon>Email
            Template</router-link
          >
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/settings"
            ><v-icon class="icon-left"> mdi-cog </v-icon>Settings</router-link
          >
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/calculator"
            ><v-icon class="icon-left"> mdi-calculator </v-icon>Calculator</router-link
          >
        </li>
        <li v-if="userDetail.userType == 'admin'">
          <router-link class="menuitems" to="/dashboard/whitelabels"
            ><v-icon class="icon-left"> mdi-account-group </v-icon>White Labels</router-link
          >
        </li>

      
        <li>
          <div class="menuitems" @click="logoutbutton">
            <v-icon class="icon-left"> mdi-power </v-icon>Logout
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  props: ["mini"],
  computed: mapGetters(["allAdminMenus"]),
  data() {
    return {
      userDetail: undefined,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    logoutbutton() {
      this.logout();
    },

    getUser() {
      return JSON.parse(localStorage.getItem("token")).user;
    },
  },
  created() {
    this.userDetail = this.getUser();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.left-fix {
  // position: fixed;
  margin-left: 20px;
  top: 0;
  bottom: 0;
  // background: $menucolor;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  .menuitems {
    color: $menulistcolor;
    font-size: $normalfont;
    display: block;
    text-decoration: none;
    padding: 10px 15px;
    margin-bottom: 5px;
    position: relative;
    margin-left: 0;
    cursor: pointer;
  }
  i {
    color: $menulistcolor;
    position: absolute;
    top: 18%;
    font-size: $iconfont;
  }
  .icon {
    &-left {
      left: -15px;
      margin-top: 5px;
    }
    &-right {
      right: 0;
    }
  }
}
.top-fix {
  position: fixed;
  left: 0;
  top: 0;
  height: 6%;
  background: $white;
  width: 85%;
  margin-left: 15%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1;
}
ul {
  list-style: none;
  padding-left: 0;
}
.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 150px;
    filter: brightness(1000);
  }
}
.tag {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: $tagcolor;
  display: block;
  font-size: $smallfont;
  padding: 3px 8px;
  color: $white;
  border-radius: 5px;
}
.second-ul {
  margin-left: 0;
  padding-left: 0;
}
.right-actions {
  display: flex;
  justify-content: flex-end;
}
.router-link-exact-active {
  position: relative;
  &::after {
    position: absolute;
    left: -100px;
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
    background: #2276b3 !important;
    z-index: -1;
  }
}
</style>