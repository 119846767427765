<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12" v-if="propertyLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-if="!propertyLoading">
          <div class="inCalculator">
            <h1 class="mb-5">Investment Calculator</h1>
            <v-form class="pt-10">
              <v-row>
                <v-col md="6">
                  <v-row>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Properties</label>
                      <v-text-field
                        @keyup="caculateInvestment"
                        v-model="properties"
                        placeholder="Properties"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Investment</label>
                      <v-text-field
                        v-model="investment"
                        placeholder="Investment"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss"
                        >Appreciation Rate (pa) (%)</label
                      >
                      <v-text-field
                        v-model="pa"
                        placeholder="Appreciation Rate (pa)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Refurb</label>
                      <v-text-field
                        v-model="refurb"
                        placeholder="Refurb"
                        outlined
                        dense
                        @keyup="caculateInvestment"
                      ></v-text-field>
                    </v-col>

                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Leverage (LTV) (%)</label>
                      <v-text-field
                        v-model="ltv"
                        placeholder="Leverage (LTV)"
                        outlined
                        dense
                        @keyup="caculateInvestment"
                      ></v-text-field>
                    </v-col>

                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Purchase</label>
                      <v-text-field
                        v-model="purchase"
                        placeholder="Purchase"
                        outlined
                        dense
                        @keyup="caculateInvestment"
                      ></v-text-field>
                    </v-col>

                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Legals</label>
                      <v-text-field
                        v-model="legals"
                        placeholder="Legals"
                        outlined
                        dense
                        @keyup="caculateInvestment"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="6">
                  <v-row>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Location</label>
                      <v-select
                        :items="locations"
                        item-text="label"
                        item-value="value"
                        v-model="clientNature"
                        outlined
                        dense
                        @change="caculateInvestment"
                      ></v-select>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Gross Yield (%)</label>
                      <v-text-field
                        v-model="grossyeild"
                        placeholder="Gross Yield"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss"
                        >Rent Increase Rate (pa)</label
                      >
                      <v-text-field
                        v-model="rentIncrease"
                        placeholder="Rent Increase Rate (pa)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Interest Rate (%)</label>
                      <v-text-field
                        v-model="interestrate"
                        placeholder="Interest Rate"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Management (%)</label>
                      <v-text-field
                        v-model="management"
                        placeholder="Management"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Op Expenses (%)</label>
                      <v-text-field
                        v-model="opexpenses"
                        placeholder="Op Expenses"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" class="py-0 btnFull">
                      <label for="" class="lableCss">Mortgage Setup (%)</label>
                      <v-text-field
                        v-model="mortgagesetup"
                        placeholder="Mortgage Setup"
                        outlined
                        dense
                        @keyup="caculateInvestment"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="12" class="btnFull">
                  <v-btn class="primaryBtn" loading v-if="loadingSave"></v-btn>
                  <v-btn class="primaryBtn" @click="validateForm" v-if="!loadingSave">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

export default {
  name: "Calculator",
  computed: {
    ...mapGetters(["postCalculator"]),
  },
  components: {
    Loading,
  },

  data() {
    return {
      snackbar: false,
      text: "",
      propertyLoading: true,
      properties: 3,
      rentIncrease: 4,
      investment: 100000,
      grossyeild: 8,
      pa: 5.6,
      ltv: 75,
      purchase: 75000,
      value: 70000,
      refurb: 4000,
      interestrate: 4,
      management: 12,
      opexpenses: 10,
      legals: 2000,
      mortgagesetup: 1,
      clientNature: true,
      locations: [
        {
          label: "UK",
          value: true,
        },
        {
          label: "International",
          value: false,
        },
      ],
      loadingSave:false
    };
  },

  methods: {
    ...mapActions(["postCalculator"]),
    caculateInvestment() {
      this.investment =
        Math.round(
          this.purchase * (1 - this.ltv / 100) +
            (this.purchase * (this.ltv / 100) * this.mortgagesetup) / 100 +
            this.refurb +
            this.legals +
            (this.clientNature
              ? this.purchase < 125000
                ? (this.purchase * 3) / 100
                : this.purchase < 250000
                ? ((this.purchase - 125000) * 5) / 100 + (125000 * 3) / 100
                : this.purchase < 925000
                ? ((this.purchase - 250000) * 13) / 100 +
                  (125000 * 5) / 100 +
                  (125000 * 3) / 100
                : this.purchase > 925000
                ? ((this.purchase - 925000) * 15) / 100 +
                  (675000 * 13) / 100 +
                  (125000 * 5) / 100 +
                  (125000 * 3) / 100
                : 0
              : this.purchase < 125000
              ? (this.purchase * 5) / 100
              : this.purchase < 250000
              ? ((this.purchase - 125000) * 7) / 100 + (125000 * 5) / 100
              : this.purchase < 925000
              ? ((this.purchase - 250000) * 15) / 100 +
                (125000 * 7) / 100 +
                (125000 * 5) / 100
              : this.purchase > 925000
              ? ((this.purchase - 925000) * 17) / 100 +
                (675000 * 15) / 100 +
                (125000 * 7) / 100 +
                (125000 * 5) / 100
              : 0)
        ) * this.properties;
    },
    async validateForm() {
      this.loadingSave = true;
      await this.postCalculator({
        properties: this.properties,
        rentIncrease: this.rentIncrease,
        investment: this.investment,
        grossyield: this.grossyeild,
        pa: this.pa,
        ltv: this.ltv,
        purchase: this.purchase,
        value: this.value,
        refurb: this.refurb,
        interestrate: this.interestrate,
        management: this.management,
        opexpenses: this.opexpenses,
        legals: this.legals,
        mortgagesetup: this.mortgagesetup,
        clientNature: this.clientNature,
        locations: this.locations,
      });
      this.loadingSave = false;
      this.snackbar = true;
      this.text = "Calculator Value has been updated successfully."
    },
  },
  computed: {
    ...mapGetters(["allCalculator"]),
  },
  async created() {
    await this.postCalculator({ getValue: true });

    if (this.allCalculator) {
      this.properties = this.allCalculator.properties;
      this.rentIncrease = this.allCalculator.rentIncrease;
      this.investment = this.allCalculator.investment;
      this.grossyeild = this.allCalculator.grossyield;
      this.pa = this.allCalculator.pa;
      this.ltv = this.allCalculator.ltv;
      this.purchase = this.allCalculator.purchase;
      this.value = this.allCalculator.value;
      this.refurb = this.allCalculator.refurb;
      this.interestrate = this.allCalculator.interestrate;
      this.management = this.allCalculator.management;
      this.opexpenses = this.allCalculator.opexpenses;
      this.legals = this.allCalculator.legals;
      this.mortgagesetup = this.allCalculator.mortgagesetup;
      this.clientNature = this.allCalculator.clientNature;
      this.locations = this.allCalculator.locations;
    }
    this.propertyLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dateHighlight {
  background: yellow;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 0;
}
.v-icon {
  font-size: 16px;
  cursor: pointer;
}
.downloadbtn {
  margin-left: 20px;
}
.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
.heightZero {
  height: 0;
}
.actionbtn {
  margin-bottom: 20px;
}
.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);
  a {
    color: rgb(5, 5, 5);
  }
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}
a {
  text-decoration: none;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;
  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}
.relRow {
  position: relative;
  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }
  &:hover {
    .dropBox {
      display: block;
    }
  }
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 500px;
    height: 500px;
  }
}
.flexBox-space {
  display: flex;
  justify-content: space-between;
}
.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
.flexend {
  justify-content: flex-end;
  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;
  h5 {
    font-size: 16px;
    margin-bottom: 5px;
    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }
  margin-bottom: 20px;
  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;
    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;
      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}
.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}
.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;
  &:nth-of-type(1) {
    left: 280px;
  }
  &:nth-of-type(2) {
    left: 420px;
  }
}
.favourite {
  color: red;
  width: 35px;
  height: 35px;
}
.favouriteO {
  width: 35px;
  height: 35px;
}
.addButton {
  // margin-left: 310px;
  min-width: 200px !important;
}
</style>
