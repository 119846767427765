<template>
  <section class="mt-5">
    <div v-if="loading">
      <Loading />
    </div>
    <form>
      <div v-if="!loading" class="offmarket">
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Image</span>
                <img :src="property.image" alt="property image" width="100%" />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Street Address</span>
                <h2>
                  <v-text-field v-model="property.address" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Estimated Rent</span>
                <h2>
                  <v-text-field v-model="property.averageRent" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Bedrooms</span>
                <h2>
                  <v-text-field v-model="property.bedrooms" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Brokerage Fees</span>
                <h2>
                  <v-text-field v-model="property.brokerage" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Council Tax Band</span>
                <h2>
                  <v-text-field v-model="property.councilTax" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Current Rent</span>
                <h2>
                  <v-text-field v-model="property.currentRent" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Deposit</span>
                <h2>
                  <v-text-field v-model="property.deposite" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Reservation Fee</span>
                <h2>
                  <v-text-field v-model="property.reservationPrice" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Door Number</span>
                <h2>
                  <v-text-field v-model="property.doorNumber" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Discount</span>
                <h2>
                  <v-text-field v-model="property.discount" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>EPC Rating</span>
                <h2>
                  <v-text-field v-model="property.epc" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Flood Risk</span>
                <h2>
                  <v-text-field v-model="property.floodRisk" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Floor Area</span>
                <h2>
                  <v-text-field v-model="property.floorsqft" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Gross Yield</span>
                <h2>
                  <v-text-field v-model="property.grossYield" outlined />
                </h2>
              </div>
            </div>
          </v-col>

          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Legals</span>
                <h2>
                  <v-text-field v-model="property.legals" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Management Fee</span>
                <h2>
                  <v-text-field v-model="property.management" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Mortgage</span>
                <h2>
                  <v-text-field v-model="property.mortgage" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Net Annual Cashflow</span>
                <h2>
                  <v-text-field v-model="property.netAnnualCashflow" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Net Return</span>
                <h2>
                  <v-text-field v-model="property.netReturn" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Current Occupancy</span>
                <h2>
                  <v-text-field v-model="property.occupancy" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Operating Expenses</span>
                <h2>
                  <v-text-field v-model="property.opex" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Zoople Valuation</span>
                <h2>
                  <v-text-field v-model="property.price" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Post TItle</span>
                <h2>
                  <v-text-field v-model="property.propertyOf" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Property Type</span>
                <h2>
                  <v-text-field v-model="property.propertyType" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Purchase Price</span>
                <h2>
                  <v-text-field v-model="property.purchasePrice" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment</span>
                <h2>
                  <v-text-field v-model="property.refurbishment" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment Cost</span>
                <h2>
                  <v-text-field v-model="property.refurbishmentCost" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Rental Income</span>
                <h2>
                  <v-text-field v-model="property.rentalIncome" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Mortgage Set-up Fees</span>
                <h2>
                  <v-text-field v-model="property.setupFee" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Land Size</span>
                <h2>
                  <v-text-field v-model="property.sizing" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Stamp Duty</span>
                <h2>
                  <v-text-field v-model="property.stampDuty" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Tenure</span>
                <h2>
                  <v-text-field v-model="property.tenureType" outlined />
                </h2>
              </div>
            </div>
          </v-col>

            <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Strategy</span>
                <h2>
                  <v-text-field v-model="property.strategy" outlined />
                </h2>
              </div>
            </div>
          </v-col>
      
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Total Investment</span>
                <h2>
                  <v-text-field v-model="property.totalInvestment" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>School</span>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.schools"
                      :key="index"
                      v-model="data.school"
                      outlined
                  /></v-col>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.schools"
                      :key="index"
                      v-model="data.distance"
                      outlined
                  /></v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Previously Sold - Same Street</span>

                <h2>
                  <v-text-field
                    v-for="(data, index) in property.onStreetCompare"
                    :key="index"
                    v-model="property.onStreetCompare[index]"
                    outlined
                  />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Previously Sold - Within The Area</span>

                <v-text-field
                  v-for="(data, index) in property.onDistrictCompare"
                  :key="index"
                  v-model="property.onDistrictCompare[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>For Sale - Within The Area</span>

                <v-text-field
                  v-for="(data, index) in property.onForSaleCompare"
                  :key="index"
                  v-model="property.onForSaleCompare[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>For Rent - Within The Area</span>

                <v-text-field
                  v-for="(data, index) in property.onForRentCompare"
                  :key="index"
                  v-model="property.onForRentCompare[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Nearest Stations</span>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.stations"
                      :key="index"
                      v-model="data.station"
                      outlined
                  /></v-col>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.stations"
                      :key="index"
                      v-model="data.distance"
                      outlined
                  /></v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Deal Benefit</span>

                <v-text-field
                  v-for="(data, index) in property.onBenefits"
                  :key="index"
                  v-model="property.onBenefits[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment</span>

                <v-text-field
                  v-for="(data, index) in property.refurbishment"
                  :key="index"
                  v-model="property.refurbishment[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="flexBox iconText">
              <v-btn v-if="!loadingUpdate" @click="updatePropertyAct"
                >Update</v-btn
              >
              <v-btn v-if="loadingUpdate" loading></v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </form>
    <v-snackbar v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </section>
</template>

<script>
import Loading from "@/components/dashboard/Loading";
import PropertyUpdate from "@/components/dashboard/PropertyUpdate";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SinglePropertyMarket",
  data: () => ({
    property: undefined,
    loading: true,
    loadingUpdate: false,
    snackbar: false,
    message: "",
  }),
  components: {
    PropertyUpdate,
    Loading,
  },
  computed: {
    ...mapGetters(["allOffMarketProperty"]),
  },
  methods: {
    ...mapActions(["getProperty", "putPropertyOffMarket"]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    async updatePropertyAct() {
      this.loadingUpdate = true;
      await this.putPropertyOffMarket({
        _id: this.property._id,
        address: this.property.address,
        averageRent: this.property.averageRent,
        bedrooms: this.property.bedrooms,
        brokerage: this.property.brokerage,
        councilTax: this.property.councilTax,
        currentRent: this.property.currentRent,
        deposite: this.property.deposite,
        discount: this.property.discount,
        epc: this.property.epc,
        floodRisk: this.property.floodRisk,
        floorsqft: this.property.floorsqft,
        grossYield: this.property.grossYield,
        legals: this.property.legals,
        management: this.property.management,
        mortgage: this.property.mortgage,
        netAnnualCashflow: this.property.netAnnualCashflow,
        netReturn: this.property.netReturn,
        occupancy: this.property.occupancy,
        opex: this.property.opex,
        price: this.property.price,
        propertyOf: this.property.propertyOf,
        propertyType: this.property.propertyType,
        purchasePrice: this.property.purchasePrice,
        refurbishment: this.property.refurbishment,
        refurbishmentCost: this.property.refurbishmentCost,
        rentalIncome: this.property.rentalIncome,
        setupFee: this.property.setupFee,
        sizing: this.property.sizing,
        stampDuty: this.property.stampDuty,
        tenureType: this.property.tenureType,
        totalInvestment: this.property.totalInvestment,
        doorNumber: this.property.doorNumber,
        benefits: this.property.onBenefits,
        stations: this.property.stations,
        onForRentCompare: this.property.onForRentCompare,
        onForSaleCompare: this.property.onForSaleCompare,
        onDistrictCompare: this.property.onDistrictCompare,
        onStreetCompare: this.property.onStreetCompare,
        reservationPrice: this.property.reservationPrice,
        strategy: this.property.strategy,
      });
      this.loadingUpdate = false;
      this.snackbar = true;
      this.message = "Property Updated";
    },
  },
  async created() {
    await this.getProperty(this.$route.params.id);
    this.property = this.allOffMarketProperty.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: 30px;
}
h2 {
  font-size: 18px;
  font-weight: 400;
}
.iconText {
  img {
    margin-right: 15px;
  }
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}
.listSingle {
  list-style: none;
  margin-top: 10px;
  padding-left: 20px;
  li {
    line-height: 30px;
  }
}
.imageproperty {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
  }
  img {
    width: 300px;
  }
}
.keyFeatures {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: -5px;
  li {
    margin: 5px 20px;
    font-size: 18px;
    width: 40%;
  }
}
.table-border {
  margin-top: 15px;
  margin-left: 20px;
  border-collapse: collapse;
  th {
    padding: 10px;
  }
  td {
    padding: 10px;
  }
}
.editBox {
  display: flex;
  align-items: center;
  font-weight: 600;
  .v-text-field {
    margin-bottom: -20px;
    font-weight: 600;
  }
}
.width100 {
  width: 100% !important;
  .v-btn {
    width: 80%;
  }
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    display: flex;
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}

.images {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 4px;
  }
  img {
    width: 220px;
  }
}
.flexBox {
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;

  strong {
    margin-top: 5px;
    display: flex;
    width: 30px;
  }
  .textBox {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.imageBox {
  background: #f4f4f4;
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
h3 {
  color: #09395a;
  font-size: 24px;
}
.boxborder {
  margin-top: 10px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 30px;
  background: $white;
  h3 {
    margin-bottom: 0;
  }
}
.fullwidth {
  width: 100%;
  .v-text-field {
    margin-top: 5px;
  }
}
.bgColor {
  margin-top: 30px;
  position: relative;
  div {
    position: relative;
    z-index: 9;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: #f4f4f4;
    left: -500px;
    right: -500px;
    height: 100%;
  }
}
.contain {
  width: 1200px;
  // left: -200px;
  // position: relative;
}
.fixedBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  text-align: center;
  img {
    width: 700px;
    margin: 100px;
  }
}
.uk-sticky {
  margin: 0;
  z-index: 999999;
  width: 200px;
  text-decoration: none;
}

.floodRisk {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  h3 {
    font-size: 16px;
  }
}
.flexBox {
  span {
    font-weight: 600;
    color: #09395a;
  }
}
.priceBox {
  .v-icon {
    margin-left: 20px;
    font-size: 30px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .wishlist {
    color: red;
  }
}
.widthFull {
  width: 100%;
}
.flex-box-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fixedLoading {
  position: fixed;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}
.checkGreen {
  display: inline-flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: green;
  align-items: center;
  justify-content: center;
  .v-icon {
    color: #fff;
    font-size: 16px !important;
  }
}
.viewListingButton {
  position: fixed;
  top: 25px;
  z-index: 999;
  height: 10px;
  width: 150px;
  right: 200px;
  a {
    text-decoration: none;
  }
}
.downloadBtn {
  width: 200px;
  cursor: pointer;
  div {
    width: 160px;
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background: $tagcolor;
    color: #fff;
    font-size: 14px;
    .v-icon {
      color: #fff;
      margin-left: 10px;
    }
  }
}
.highRisk {
  background: rgba(255, 0, 0, 0.2);
}
</style>